import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'MENU',
    isTitle: true
  },
  {
    label: 'Tableau de bord',
    icon: 'home',
    link: '/boutique/dashboard'
  },
  {
    label: 'Boutique',
    isTitle: true
  },
  {
    label: 'Ma boutique',
    icon: 'shopping-bag',
    link: '/boutique/ma-boutique'
  },
  {
    label: 'COMMANDES',
    isTitle: true
  },
  {
    label: 'Mes commandes',
    icon: 'shopping-cart',
    link: '/boutique/mes-commandes'
  },
  {
    label: 'Mes paiements',
    icon: 'credit-card',
    link: '/boutique/mes-paiements'
  },
  {
    label: 'Catalogue',
    isTitle: true
  },
  {
    label: 'Mes produits',
    icon: 'package',
    link: '/boutique/mes-produits'
  },
  {
    label: 'Mes Catégories',
    icon: 'layers',
    link: '/boutique/mes-categories'
  },
  {
    label: 'Codes promo',
    icon: 'gift',
    link: '/boutique/promos'
  },
  {
    label: 'CONFIGURATION',
    isTitle: true
  },
  {
    label: 'Utilisateurs',
    icon:  'users',
    link:  '/boutique/utilisateurs'
  },
  {
    label: 'Commentaires',
    icon: 'feather',
    link: '/boutique/commentaires'
  },
  {
    label: 'Attributs de produits',
    icon: 'list',
    link: '/boutique/attributes'
  },
  {
    label: 'Modes de livraison',
    icon: 'truck',
    link: '/boutique/mode-livraison'
  },
  /* {
    label: 'Mode de paiement',
    icon: 'credit-card',
    link: '/boutique/mode-paiement'
  }, */
];
