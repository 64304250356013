import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { Role } from '../helpers/role.enum';
import { ProfileState } from '../store/profile.state';
import { Store } from '@ngrx/store';
import { setProfile } from '../store/profiles.actions';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor( 
                private router: Router , 
                private userService :UserService ,
                private store: Store<ProfileState>

              ) { }


/* ----------------------- IS ADMIN --------------------- */

  async isAdmin() {

          let access_token = localStorage.getItem('access_token') ;
 
          if(!access_token){
              this.router.navigate(['/auth']);
          }

          
           (await this.userService.getUserRole()).subscribe({

            next: (res :any) => { 
                     
                        let role = res.data.role ;

                        // console.log("PROFILE:",res.data);
                        
                        if(role=== Role.Admin){

                          // update profile
                          let profile = {
                            image: res.data.profile_image ,
                            fname : res.data.fname  ,
                            lname : res.data.lname ,
                            email : res.data.email ,
                            phone : res.data.phone  ,
                          }
                          
                          this.store.dispatch(setProfile(profile))

                          return true ;

                        }else{
                            // go to login
                          this.router.navigate(['/auth']);
                          return false 
                        }
                              
            },   
            error: (error) => {

                      // go to login
                      this.router.navigate(['/auth']);
                      return false 
                
            },     
            complete:() => { }, 
  
          }); ;
  }
/* ----------------------- IS STORE --------------------- */

async isStore() {

  let access_token = localStorage.getItem('access_token') ;

  if(!access_token){
      this.router.navigate(['/auth']);
  }

   (await this.userService.getUserRole()).subscribe({

    next: (res :any) => { 
             
                let role = res.data.role ;
  
                if(role=== Role.Store ||role=== Role.Product || role=== Role.Order){
                  return true ;
                }else{
                    // go to login
                  this.router.navigate(['/auth']);
                  return false 
                }
                      
    },   
    error: (error) => {

              // go to login
              this.router.navigate(['/auth']);
              return false 
        
    },     
    complete:() => { }, 

  }); ;


}
   /* ----------------------- IS ANNONCEUR --------------------- */

async isAnnonceur() {

  let access_token = localStorage.getItem('access_token') ;

  if(!access_token){
      this.router.navigate(['/auth']);
  }

   (await this.userService.getUserRole()).subscribe({

    next: (res :any) => { 
             
                let role = res.data.role ;
  
                if(role=== Role.Annonce){
                  return true ;
                }else{
                    // go to login
                  this.router.navigate(['/auth']);
                  return false 
                }
                      
    },   
    error: (error) => {

              // go to login
              this.router.navigate(['/auth']);
              return false 
        
    },     
    complete:() => { }, 

  }); ;


}

}
