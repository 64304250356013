import { MenuItem } from './menu.model';

export const MENU_ORDER: MenuItem[] = [
 
  {
    label: 'COMMANDES',
    isTitle: true
  },
  {
    label: 'Mes commandes',
    icon: 'shopping-cart',
    link: '/boutique/mes-commandes'
  },
  {
    label: 'Mes paiements',
    icon: 'credit-card',
    link: '/boutique/mes-paiements'
  },
  
];
