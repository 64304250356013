import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';


import { getProfile } from '../../../../store/profile.selector';
import { ProfileState } from '../../../../store/profile.state';
import { Store } from '@ngrx/store';
import { setProfile } from 'src/app/store/profiles.actions';
import { FormBuilder, Validators } from '@angular/forms';
import * as bootstrap from "bootstrap";
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/utils/alert.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  submitted: boolean = false ;

  constructor(
      @Inject(DOCUMENT) private document: Document, 
      private renderer: Renderer2,
      private router: Router,
      private formBuilder :FormBuilder,
      private store: Store<ProfileState>,
      private alertService: AlertService,
      private userService: UserService,
      private permissionService: PermissionService,
   ) { }

   // edit image 
    edit_image_file : any ;
    @ViewChild('target_edit_image',{static: true}) target_edit_image!: ElementRef;
    @ViewChild('target_edit_image_input',{static: true}) target_edit_image_input!: ElementRef;

    password_modal :any ;
    edit_modal :any ;

    pass_word :any
    old_pass_word :any

    image_base_url = environment.image_base_url ;

    user :any = {}

    
   ngOnInit(): void {

    let profile = this.store.select(getProfile)
    profile.subscribe((user :any) =>{
      this.user = user ;
       console.log("USER",this.user);
       
    }) ;
    }

  /*----------------------- formBuilder --------------------------------------*/

    public addFormGroup = this.formBuilder.group({
          fname: ['',[Validators.required]],
          lname: ['',[Validators.required]],
          email: ['',[Validators.required]],
          phone: ['',[Validators.required]],
     });

  /* ----------------------  VALIDATION  -------------------------------------- */
 
  get fname(){
    return this.addFormGroup.get('fname');
  }

  get lname(){
    return this.addFormGroup.get('lname');
  }

  get email(){
    return this.addFormGroup.get('email');
  }

  get phone(){
    return this.addFormGroup.get('phone');
  }
  
/* ----------------  click add image icon  ------------------------------------ */
  clickEditImageInput(){
    this.target_edit_image_input.nativeElement.click() ;   
  }
/* -------------  On open add file input ---------------------------- */

  handleEditImage(elm :any) {
        
    // initialise variables
    this.edit_image_file = null ;

    if (elm.target.files && elm.target.files[0]) {

        let file = elm.target.files[0] ;
      
        if (file.type.match(/image\/*/) == null) { return; }
        
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {

              if((event.target?.result)){
                this.target_edit_image.nativeElement.src = event.target.result

                // set file to send 
                this.edit_image_file = file ;
              } 
          }
        }
  }
 
/*-----------------------  Open edit category modal  ----------------------- */

openEditModal() {

        let profile = this.store.select(getProfile)
          
        profile.subscribe((user :any) =>{
               
          console.log("USER:: ",user);

          this.addFormGroup.patchValue({

            fname: user.fname,
            lname: user.lname,
            email: user.email,
            phone: user.phone
    
          });

          // reset image src 
          this.target_edit_image.nativeElement.src = this.image_base_url+user.image ;

          // show modal 
          const modalElement: HTMLElement | null = document.querySelector("#editProfileModal");
          if (!modalElement) { return; }
          this.edit_modal = new bootstrap.Modal(modalElement, {});
          this.edit_modal.show()
          
        }) ;
 }


/*-----------------------  Open add modal  ----------------------- */

openPasswordModal() {

  this.edit_modal.hide()

  const modalElement: HTMLElement | null = document.querySelector("#passwordModal");
  if (!modalElement) { return; }
  this.password_modal = new bootstrap.Modal(modalElement, {});
  this.password_modal.show() ;

 }
 
/* ------------------------- Editer la boutique  ------------------------------------- */


async submitEdit(){ // sss

  this.submitted = true ;


  let fname = this.fname?.value ;
  let lname = this.lname?.value ;
  let email = this.email?.value ;
  let phone = this.phone?.value ;

  

    if(this.addFormGroup.invalid ){
        return ; 
    }
   
  let fData = new FormData() ;
     
    fData.append('fname',fname?fname:"") ;
    fData.append('lname',lname?lname:"") ;
    fData.append('email',email?email:"") ;
    fData.append('phone',phone?phone:"") ;

    // logo
    fData.append('profile_image',this.edit_image_file)

 

  // for debug purpose ;
  for (var pair of fData.entries()) { console.log(pair[0] + ', ' + pair[1]); }

     
  await this.userService.updateMyInfos(fData).subscribe({
    next: (res :any) => { 
       // show alert
       this.alertService.alertSuccess("Edité") ;
      //
     // hide modal 
    
     this.permissionService.isAdmin()
     this.edit_modal.hide() ;

     },   
    error: (error) => {
      
      this.submitted = false ;

      if(error.status==400){
         
      }else{
         this.alertService.alertError(error.error.message) ;
      }
      console.log(error);
    },     
    complete:() => { 
          
      }, 

  });

   

}
 
/* ------------------------- change the password  ------------------------------------- */


async changePassword(){ 


  if(!this.old_pass_word || !this.pass_word){ return ;}

  let data = { 
    current_password : this.old_pass_word, 
    new_password : this.pass_word, 
  }


  await this.userService.changePassword(data).subscribe({

    next: (res :any) => { 

      this.submitted = false ;
      this.old_pass_word = "" ;
      this.pass_word = "" ;

      // show alert
      this.alertService.alertSuccess("Le mot de passe a changé.") ;

       // hide modal 
       this.password_modal.hide() ;
       
       this.logout() ;

      
     },   
    error: (error) => {
      
      this.submitted = false ;

      if(error.status==400){
         
      }else{
         this.alertService.alertError(error.error.message) ;
      }
      console.log(error);
    },     
    complete:() => { 
          
      }, 

  });
   
 }
 

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

/* ----------------------- logout ---------------------------------------- */

  logout() {

        localStorage.removeItem('access_token');
        if (!localStorage.getItem('access_token')) {
          this.router.navigate(['/auth/login']);
        }

  }


}
