
export enum Role {
    Admin = 'ROLE_ADMIN',
    Store = 'ROLE_STORE',
    Annonce = 'ROLE_ANNONCE',
    Client = 'ROLE_CLIENT',
    Product = 'ROLE_PRODUCT',
    Order = 'ROLE_ORDER',
  }

    