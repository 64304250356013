

export const environment = {
      production: false,
      admin_base_url : "https://umaplace.api.ajicreative.club/admin/",
      api_base_url : "https://umaplace.api.ajicreative.club/api/",
      image_base_url : "https://umaplace.api.ajicreative.club/upload/media/"

};

