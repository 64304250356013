import { MenuItem } from './menu.model';

export const MENU_ANNONCEUR: MenuItem[] = [
 
  {
    label: 'COMMANDES',
    isTitle: true
  },
  {
    label: 'Mon compte',
    icon: 'users',
    link: '/annonceur/mon-compte'
  },
  {
    label: 'Mes annonces',
    icon: 'credit-card',
    link: '/annonceur/mes-annonces'
  },
  
];
