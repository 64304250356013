import { MenuItem } from './menu.model';

export const MENU_PRODUCT: MenuItem[] = [
 
  {
    label: 'Catalogue',
    isTitle: true
  },
  {
    label: 'Mes produits',
    icon: 'package',
    link: '/boutique/mes-produits'
  },
  {
    label: 'Mes Catégories',
    icon: 'layers',
    link: '/boutique/mes-categories'
  },
   
  {
    label: 'CONFIGURATION',
    isTitle: true
  },
  {
    label: 'Attributs de produits',
    icon: 'list',
    link: '/boutique/attributes'
  }
];
